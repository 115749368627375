<template>
  <v-list-item active-class="primary" @click="logout">
    <v-list-item-icon>
      <v-icon>mdi-logout</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $t("logoutItem") }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { authStoreActions } from "@/store/modules/auth/constNames";
import { userInfoStoreActions } from "@/store/modules/userInfo/constNames";

export default {
  name: "LogoutItem",
  methods: {
    logout() {
      this.$store.dispatch(authStoreActions.AUTH_LOGOUT);
      this.$store.dispatch(userInfoStoreActions.REMOVE_USER);
      this.$router.history.current.path !== "/" && this.$router.replace("/");
    },
  },
};
</script>
